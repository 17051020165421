import logo from './logo.svg';
import './App.css';
import { BillboardMultiLineChart } from './BillboardMultiLineChart.js';

function App() {
  return (
    <div className="App">
      <BillboardMultiLineChart
        dataUrl = {'maybe not needed'}
      />
    </div>
  );
}

export default App;
